import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { PopupModal } from "react-calendly";
import Video from "../../assets/bgVideo.mp4";

const splitText = (text) =>
  text.split("").map((char, index) => ({
    char,
    key: `${char}-${index}`,
  }));

const letterAnimation = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
};

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (videoRef.current) {
        videoRef.current.style.transform = `translateY(${scrollTop * 0.5}px)`;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const title1 = "Instantly captivate audiences, while they’re on the move.";
  //   const title2 = "move.";

  return (
    <div className="bg-white relative overflow-hidden">
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={Video}
        autoPlay
        loop
        muted
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-55"></div>
      <div className="relative z-10">
        <div className="relative isolate overflow-hidden px-6 sm:px-16 pb-10 pt-10 md:pt-24 lg:flex lg:items-center lg:justify-center lg:px-24 lg:pt-0 lg:h-screen">
          <div className="mx-auto max-w-lg text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-center">
            <motion.h2
              className="text-6xl font-bold tracking-tight text-custom-pink font-AsteroneBold sm:text-6xl"
              initial="initial"
              animate="animate"
              transition={{ staggerChildren: 0.05 }}
            >
              {splitText(title1).map(({ char, key }) => (
                <motion.span key={key} variants={letterAnimation}>
                  {char}
                </motion.span>
              ))}
            </motion.h2>
            <motion.p
              className="mt-6 text-lg leading-8 text-gray-300"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5 }}
            >
              Get your brand in front of valuable audiences during the most
              boring part of their day — their commute. 🚖
            </motion.p>
            <div className="mt-10 flex flex-col items-center justify-center gap-y-4 gap-x-6 lg:flex-row">
              <button
                onClick={openModal}
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Book a Meeting
              </button>
            </div>
          </div>
        </div>
      </div>
      <PopupModal
        url="https://calendly.com/nader-nasr-easyadsmedia/30min"
        onModalClose={closeModal}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
    </div>
  );
};

export default Hero;
