import React, { useEffect, useRef, useState } from "react";

// Import the partner logos
import partner2 from "../../assets/partners/MEDICA-2.jpg.webp";
import partner3 from "../../assets/partners/RIO-1.jpg.webp";
import partner4 from "../../assets/partners/SEPTONA-1.jpg.webp";
import partner5 from "../../assets/partners/SMAC-1.jpg.webp";
import partner6 from "../../assets/partners/WC-1.jpg.webp";
import partner7 from "../../assets/partners/WETBRUSH-1.jpg.webp";
import partner8 from "../../assets/partners/bioten.jpg";
import partner9 from "../../assets/partners/carroten-1.jpg";
import partner10 from "../../assets/partners/delektia-1.png.webp";
import partner11 from "../../assets/partners/everyday.jpg.webp";
import partner12 from "../../assets/partners/fornet.jpg.webp";
import partner13 from "../../assets/partners/iasi.jpg.webp";
import partner14 from "../../assets/partners/jordan-1.jpg.webp";
import partner15 from "../../assets/partners/meritoblack-1.jpg.webp";
import partner16 from "../../assets/partners/noor1-1.jpg";
import partner17 from "../../assets/partners/omino-1.jpg.webp";
import partner18 from "../../assets/partners/omron-1.jpg.webp";
import partner19 from "../../assets/partners/recola-1.png.webp";
import partner20 from "../../assets/partners/sally-1.jpg.webp";
import partner21 from "../../assets/partners/souriti-1.jpg";
import partner22 from "../../assets/partners/tropicana-slim-logo-new.png.webp";
import partner23 from "../../assets/partners/txon-1.png.webp";
import partner0 from "../../assets/partners/Logo-removebg-preview.png";
import { PopupModal } from "react-calendly";

// Import more logos as needed

const partners = [
  { src: partner2, alt: "Medica" },
  { src: partner0, alt: "RIO MARE" },
  { src: partner3, alt: "Partner 3" },
  { src: partner4, alt: "Partner 4" },
  { src: partner5, alt: "Partner 4" },
  { src: partner6, alt: "Partner 4" },
  { src: partner7, alt: "Partner 4" },
  { src: partner8, alt: "Partner 4" },
  { src: partner9, alt: "Partner 4" },
  { src: partner10, alt: "Partner 4" },
  { src: partner11, alt: "Partner 4" },
  { src: partner12, alt: "Partner 4" },
  { src: partner13, alt: "Partner 4" },
  { src: partner14, alt: "Partner 4" },
  { src: partner15, alt: "Partner 4" },
  { src: partner16, alt: "Partner 4" },
  { src: partner17, alt: "Partner 4" },
  { src: partner18, alt: "Partner 4" },
  { src: partner19, alt: "Partner 4" },
  { src: partner20, alt: "Partner 4" },
  { src: partner21, alt: "Partner 4" },
  { src: partner22, alt: "Partner 4" },
  { src: partner23, alt: "Partner 4" },

  // Add more partners as needed
];

const Partners = () => {
  const scrollRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let scrollAmount = 0;

    const scrollStep = () => {
      if (scrollContainer) {
        scrollAmount += 1;
        if (scrollAmount >= scrollContainer.scrollWidth) {
          scrollAmount = 0;
        }
        scrollContainer.scrollLeft = scrollAmount;
      }
    };

    const scrollInterval = setInterval(scrollStep, 20);
    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <div className="bg-white py-12 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-base text-custom-pink font-semibold tracking-wide uppercase">
            Our Partners
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Trusted by Industry Leaders
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            We are proud to collaborate with these esteemed partners to deliver
            exceptional services and solutions.
          </p>
          <button
            onClick={openModal}
            className=" mt-3 rounded-md bg-custom-pink px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            Join Us
          </button>
        </div>
        <div
          ref={scrollRef}
          className="flex space-x-8 overflow-x-hidden whitespace-nowrap"
        >
          {partners.map((partner, index) => (
            <div key={index} className="flex-shrink-0">
              <img
                className="h-32 w-auto"
                src={partner.src}
                alt={partner.alt}
              />
            </div>
          ))}
        </div>
      </div>
      <PopupModal
        url="https://calendly.com/nader-nasr-easyadsmedia/30min"
        onModalClose={closeModal}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
    </div>
  );
};

export default Partners;
