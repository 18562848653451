import React from 'react';
import { Parallax } from 'react-parallax';
import heatmapImage from '../../assets/heatmap.png'; // Replace with the actual path to your image

const Heatmap = () => {
  return (
    <div className="relative bg-gray-800 text-white">
      <Parallax bgImage={heatmapImage} strength={500} bgImageStyle={{ objectFit: 'cover' }}>
        <div className="relative h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[40vh] flex items-center justify-center text-center">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-10">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4 text-black">Heatmap Analysis</h1>
            <p className="text-lg md:text-xl lg:text-2xl mb-8 text-black">Discover the insights from our state of the art heatmap and geolocation advertising.</p>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default Heatmap;
