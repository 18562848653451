import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "@react-hook/window-size";
import "tailwindcss/tailwind.css";
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const EasterEgg = () => {
  const [showConfetti, setShowConfetti] = useState(true);
  const [width, height] = useWindowSize();
  const [numberOfPieces, setNumberOfPieces] = useState(1000);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    phone: '',
    interest: '',
    subject: '',
    message: '',
    consent: false,
    notARobot: false,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_66a7u4o',
      'template_857geft',
      formData,
      'Biuo-xx3R_rHhGr0C'
    )
    .then((result) => {
      console.log(result.text);
      navigate('/thank-you');
    }, (error) => {
      console.log(error.text);
      alert("An error occurred, please try again later.");
    });
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 5000); // Start reducing after 5 seconds
  
      const interval = setInterval(() => {
        setNumberOfPieces(prev => Math.max(0, prev - 1000)); // Reduce by 50 pieces every interval
      }, 100);
  
      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }, 5000); // Stop confetti after 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className=" items-center justify-center min-h-screen bg-black text-green-500">
      {showConfetti &&  <Confetti
          width={width}
          height={height}
          numberOfPieces={numberOfPieces} // Increase the number of pieces
          gravity={0.3} // Adjust gravity to make confetti fall slower
        />}
      
      <div className="text-center p-10 bg-gray-800 rounded-lg shadow-lg max-w-full">
        <h1 className="text-6xl font-mono mb-4">Easter Egg Found!</h1>
        <p className="text-2xl mb-8">
          Congratulations, you discovered the secret prize!
        </p>

      </div>
      <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name *</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name *</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email *</label>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone *</label>
              <input
                id="phone"
                name="phone"
                type="number"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="+962 ___ - ___ - ____"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="interest" className="block text-sm font-medium text-gray-700">You are interested in *</label>
              <select
                id="interest"
                name="interest"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={formData.interest}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                <option value="advertising">Software Development</option>
                <option value="partnership">Marketing</option>
                <option value="partnership">Other</option>
                <option value="general">Just here to say hello!</option>
              </select>
            </div>
            <div className="md:col-span-2">
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Title *</label>
              <input
                id="subject"
                name="subject"
                type="text"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Hey I am interested in..."
                value={formData.subject}
                onChange={handleChange}
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message *</label>
              <textarea
                id="message"
                name="message"
                required
                className="mt-1 p-8 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div className="md:col-span-2">
              <label className="flex items-start text-sm font-medium text-gray-700">
                <input
                  name="consent"
                  type="checkbox"
                  required
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={formData.consent}
                  onChange={handleChange}
                />
                <span className="ml-2">
                  I consent to EasyAds receiving my information in order to inform me about the matter.
                </span>
              </label>
            </div>
            <div className="md:col-span-2">
              <label className="flex items-start text-sm font-medium text-gray-700">
                <input
                  name="notARobot"
                  type="checkbox"
                  required
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={formData.notARobot}
                  onChange={handleChange}
                />
                <span className="ml-2">I am not a robot</span>
              </label>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-custom-pink hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

export default EasterEgg;
