import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { PopupModal } from "react-calendly";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <footer className="bg-gray-800 text-white py-4 mt-auto bottom-0">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
          <Link to="/" className="mb-4 md:mb-0 md:mr-6">
            <img src={Logo} alt="EasyAds Media" className="h-12" />
          </Link>
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            &copy; {new Date().getFullYear()} EasyAds Media. All rights
            reserved.
          </span>
        </div>
        <ul className="flex flex-wrap items-center text-sm font-medium text-gray-500 dark:text-gray-400">
          <li>
            <button
              onClick={openModal}
              className="hover:underline me-4 md:me-6 text-custom-pink"
            >
              Book a Meeting
            </button>
          </li>
          <li>
            <Link to="/whatwedo" className="hover:underline me-4 md:me-6">
              What We Do
            </Link>
          </li>
          <li>
            <Link to="/privacy-policy" className="hover:underline me-4 md:me-6">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/contact" className="hover:underline">
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <PopupModal
        url="https://calendly.com/nader-nasr-easyadsmedia/30min"
        onModalClose={closeModal}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
    </footer>
  );
};

export default Footer;
