import React from 'react';
import { motion } from 'framer-motion';
import { FaChartLine, FaBullhorn, FaUsers, FaMobileAlt } from 'react-icons/fa';
import SampleVideo from '../../assets/Burger.mp4';

const services = [
  {
    icon: <FaChartLine size={40} className="text-blue-500" />,
    title: "Market Analysis",
    description: "Comprehensive market analysis to help you understand the competitive landscape and make informed decisions."
  },
  {
    icon: <FaBullhorn size={40} className="text-blue-500" />,
    title: "Advertising",
    description: "Innovative advertising solutions to increase your brand's visibility and reach your target audience effectively."
  },
  {
    icon: <FaUsers size={40} className="text-blue-500" />,
    title: "Diverse Demographics",
    description: "Reach a diverse and engaged audience with our high-traffic screens. Your ads will capture the attention of valuable viewers, maximizing visibility, impact and ROI"
  },
  {
    icon: <FaMobileAlt size={40} className="text-blue-500" />,
    title: "Call to Action",
    description: "Tailored mobile marketing strategies to engage your customers on the go and enhance your digital presence."
  }
];

const Services = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-custom-pink font-semibold tracking-wide uppercase">Our Services</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What We Offer
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            We provide a wide range of services to help your business grow and succeed in the competitive market.
          </p>
        </div>
        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-10">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className="relative"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-gray-100 text-blue-500">
                    {service.icon}
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{service.title}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{service.description}</dd>
              </motion.div>
            ))}
          </dl>
        </div>

        {/* New Section with Video and Text */}
        <div className="mt-16 flex flex-col lg:flex-row items-center lg:items-start lg:space-x-12">
          <motion.div
            className="w-full lg:w-1/2"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <video className="w-full h-auto rounded-lg shadow-lg" autoPlay loop muted preload='true'>
              <source src={SampleVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </motion.div>
          <motion.div
            className="w-full lg:w-1/2 mt-8 lg:mt-0"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-extrabold text-gray-900 mb-4">We create rich custom dynamic ads</h2>
            <p className="text-lg text-gray-500 mb-4">Our cutting-edge technology and innovative solutions help your business stay ahead of the competition.</p>
            <p className="text-lg text-gray-500">Join us and experience the future of digital marketing and advertising with EasyAds Media.</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Services;
