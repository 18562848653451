// import React from 'react';
// import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';

// const Captain = () => {
//   return (
//     <div className="bg-gray-100 py-12" dir="rtl">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="text-center">
//           <motion.h1
//             className="text-4xl font-bold text-gray-900"
//             initial={{ opacity: 0, y: -20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.8 }}
//           >
//             كن كابتن
//           </motion.h1>
//           <motion.p
//             className="mt-4 text-lg text-gray-500"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.8 }}
//           >
//             انضم إلى شبكتنا من الكباتن وابدأ في كسب المال عن طريق عرض الإعلانات في سيارتك. تقدم EasyAds Media أحدث حلول الإعلان داخل السيارة لمساعدتك على زيادة أرباحك.
//           </motion.p>
//         </div>

//         <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {[
//             {
//               title: 'لماذا تنضم إلينا؟',
//               description:
//                 'اكسب دخلًا إضافيًا عن طريق عرض الإعلانات في سيارتك. تم تصميم منصتنا لتوفير أكبر قدر من المرونة والتحكم في الإعلانات المعروضة.',
//             },
//             {
//               title: 'كيف تعمل؟',
//               description:
//                 'ببساطة قم بتثبيت جهاز العرض الخاص بنا في سيارتك وابدأ في كسب المال. يمكنك تتبع أرباحك وأداء الإعلانات من خلال لوحة التحكم السهلة الاستخدام.',
//             },
//             {
//               title: 'ابدأ الآن',
//               description:
//                 'جاهز للانضمام؟ اضغط على الزر أدناه للتسجيل وكن كابتن اليوم!',
//               link: (
//                 <Link
//                   to="/driver-contact"
//                   className="mt-6 inline-block bg-custom-pink hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
//                 >
//                   سجل الآن
//                 </Link>
//               ),
//             },
//           ].map((item, index) => (
//             <motion.div
//               key={index}
//               className="bg-white rounded-lg shadow-md p-6"
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.8, delay: index * 0.2 }}
//             >
//               <h2 className="text-2xl font-semibold text-gray-900">{item.title}</h2>
//               <p className="mt-4 text-gray-600">{item.description}</p>
//               {item.link}
//             </motion.div>
//           ))}
//         </div>

//         <div className="mt-16">
//           <motion.h2
//             className="text-3xl font-bold text-gray-900 text-center"
//             initial={{ opacity: 0, y: -20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.8 }}
//           >
//             الأسئلة الشائعة
//           </motion.h2>
//           <div className="mt-8 space-y-6">
//             {[
//               {
//                 question: 'كم يمكنني أن أكسب؟',
//                 answer:
//                   'تعتمد الأرباح على عوامل مختلفة مثل عدد الساعات التي تقودها والمناطق التي تغطيها. يمكن لكباتننا كسب مبلغ كبير من الدخل الإضافي.',
//               },
//               {
//                 question: 'هل عملية التثبيت صعبة؟',
//                 answer:
//                   'ليس على الإطلاق! سيرشدك فريقنا خلال عملية التثبيت البسيطة، وستكون جاهزًا للعمل في وقت قصير.',
//               },
//               {
//                 question: 'كيف أتتبع أرباحي؟',
//                 answer:
//                   'يمكنك تتبع أرباحك وأداء الإعلانات من خلال لوحة التحكم السهلة الاستخدام، والمتاحة من أي جهاز.',
//               },
//             ].map((faq, index) => (
//               <motion.div
//                 key={index}
//                 className="bg-white rounded-lg shadow-md p-6"
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8, delay: index * 0.2 }}
//               >
//                 <h3 className="text-xl font-semibold text-gray-900">{faq.question}</h3>
//                 <p className="mt-4 text-gray-600">{faq.answer}</p>
//               </motion.div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Captain;

import React from 'react'

const Captain = () => {
  const goToHome = () => {
    window.location.href = '/';
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">قريباً</h1>

        <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
        <p className="text-lg mb-8">We're working hard to bring you this page.</p>
        <button
          className="px-6 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
          onClick={goToHome}
        >
         الموقع الرئيسي
        </button>
        <br/>
        <br/>

        <button
          className="px-6 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
          onClick={goToHome}
        >
          Home
        </button>
      </div>
    </div>
  );
};

export default Captain
