import React, { useState } from "react";
import { PopupModal } from "react-calendly";

const ThankYou = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h1 className="text-3xl font-bold text-gray-900">Thank You!</h1>
        <p className="mt-4 text-lg text-gray-500">We will contact you soon!</p>
        <button
          onClick={openModal}
          className="rounded-md bg-custom-pink text-white px-3.5 py-2.5 text-sm font-semibold mt-4 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
        >
          Book a Meeting
        </button>
      </div>
      <PopupModal
        url="https://calendly.com/nader-nasr-easyadsmedia/30min"
        onModalClose={closeModal}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
    </div>
  );
};

export default ThankYou;
