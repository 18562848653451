import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/NavBar/NavBar";
import Hero from "./components/Hero/Hero";
import Features from "./components/Features/Features";
import Loading from "./components/Loading/Loading";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import NotFound from "./components/NotFound/NotFound";
import ThankYou from "./components/Contact/ThankYou";
import Captain from "./components/Captain/Captain";
import DriverContact from "./components/Contact/DriverContact";
import Services from "./components/Services/Services";
import WhoWeAre from "./components/WhoWeAre/WhoWeAre";
import PrivacyPolicy from "./components/PrivacyPolicy.jsx/PrivacyPolicy";
import Heatmap from "./components/Heatmap/Heatmap";
import Dashboard from "./components/Dashboard/Dashboard";
import Partners from "./components/Partners/Partners";
import EasterEgg from "./components/EasterEgg/EasterEgg";

function App() {
  const [loading, setLoading] = useState(true);
  const [loadingTime, setLoadingTime] = useState(2000);

  // useEffect(() => {
  //   const randomLoadingTime =
  //     Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
  //   setLoadingTime(randomLoadingTime);

  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, randomLoadingTime);

  //   return () => clearTimeout(timer);
  // }, []);

  // if (loading) {
  //   return <Loading loadingTime={loadingTime} />;
  // }

  return (
    <Router>
      <div className=" flex flex-col min-h-screen">
        <Navbar />
        <div className="pt-16">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Dashboard />
                  <Features />
                  <Heatmap />
                  <Partners />
                </>
              }
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/captain" element={<Captain />} />
            <Route path="/driver-contact" element={<DriverContact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/whatwedo" element={<WhoWeAre />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/easter-egg" element={<EasterEgg />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
