import React from "react";
import { motion } from "framer-motion";
import easyAdsImage from "../../assets/CarAds.mov"; // Make sure to replace with the actual path to your image

const WhoWeAre = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl font-extrabold text-gray-900">
              Discover EasyAds Media
            </h2>
            <p className="mt-4 text-lg text-gray-700">
              Welcome to EasyAds Media – the cutting-edge platform transforming
              in-car advertising. Our state-of-the-art digital display network
              enables brands to engage directly with passengers, while offering
              ride-share vehicle owners an innovative revenue stream. Together,
              we are harnessing the potential of captive audiences on the move,
              setting a new standard in ride-based advertising!
            </p>
            <p className="mt-4 text-lg text-gray-700">
              EasyAds Media leverages proprietary, advanced, in-house developed software
              technology to deliver a dynamic in-car digital content and
              advertising network. Our strategically placed electronic displays
              within vehicles are centrally managed to precisely target
              passengers. These displays provide a rich array of content,
              including video ads, information, and entertainment. Our offerings
              include high-definition videos, captivating images, interactive
              banners, enticing coupons, and scannable QR codes.{" "}
            </p>
            <p className="mt-4 text-lg text-gray-700">
              Join us at EasyAds Media, where we are revolutionizing the travel
              experience with engaging and impactful in-car advertising
              solutions.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <video className="w-full h-auto rounded-lg shadow-lg" autoPlay loop muted preload='true'>
              <source src={easyAdsImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
