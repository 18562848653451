import React, { useRef } from 'react';
import { FaRocket, FaRegHandPointUp, FaRoute, FaChartLine, FaMapMarkedAlt } from 'react-icons/fa';
import { BsQrCode } from "react-icons/bs";
import { motion, useInView } from 'framer-motion';

const features = [
  {
    icon: <FaRocket size={40} className="text-custom-pink" />,
    title: "Ultimate Flexibility - Never be stuck with an outdated ad again! ",
    description: "Our platform lets you change your campaigns in minutes, ensuring your message is always fresh and relevant."
  },
  {
    icon: <FaRegHandPointUp size={40} className="text-custom-pink" />,
    title: "Live Interactions",
    description: "How many passengers are interacting with your ads."
  },
  {
    icon: <FaChartLine size={40} className="text-custom-pink" />,
    title: "Real time Dashboard",
    description: "A live timeline of impressions, reach, interactions, reroutes and scans as your ads are shown across the city."
  },
  {
    icon: <FaRoute size={40} className="text-custom-pink" />,
    title: "Reroutes",
    description: "With QR Coupons, the number of passengers that have purchased from your business."
  },
  {
    icon: <FaMapMarkedAlt size={40} className="text-custom-pink" />,
    title: "Heatmaps",
    description: "With QR Coupons, the number of passengers that have purchased from your business."
  },
  {
    icon: <BsQrCode size={40} className="text-custom-pink" />,
    title: "QR Increase Website Traffic",
    description: "With QR Codes, increase the number of people visiting your website."
  }
];

const FeatureItem = ({ feature, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      className="relative"
      initial={{ opacity: 0, y: 100 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, delay: index * 0.3 }}
    >
      <dt>
        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-gray-100 text-white">
          {feature.icon}
        </div>
        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.title}</p>
      </dt>
      <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
    </motion.div>
  );
};

const Features = () => {
  return (
    <div className="bg-white py-12 lg:h-screen flex items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-custom-pink font-semibold tracking-wide uppercase">Features</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            GROW YOUR AUDIENCE
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            EasyAds Media is an in-car digital content and advertising display network utilizing cutting-edge front-end and back-end technology. Our network of displays, strategically placed in ride-share cars, is centrally managed to effectively target passengers with tailored content.
          </p>
        </div>
        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
            {features.map((feature, index) => (
              <FeatureItem key={index} feature={feature} index={index} />
            ))}
          </dl>
        </div>
        <h2 className="flex items-center justify-center pt-10 text-base text-custom-pink font-semibold tracking-wide uppercase">And More!</h2>
      </div>
    </div>
  );
};

export default Features;
