import React from "react";
import { Parallax } from "react-parallax";
import DashboardScreenshot from "../../assets/Car.png"; // Replace with the actual path to your image

const Dashboard = () => {
  return (
    <div className="relative text-white">
      <Parallax
        bgImage={DashboardScreenshot}
        strength={800}
        bgImageStyle={{ objectFit: "cover" }}
      >
        <div className="relative h-[80vh] sm:h-[60vh] md:h-[70vh] lg:h-[40vh] flex items-center justify-center text-center">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-10 ">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-black opacity-80 py-4 rounded-3xl px-7">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4 text-white">
                  100+ <br/> Screens
                </h1>
                <p className=" flex flex-row justify-end"><i>*increased on demand</i></p>
              </div>
              <div className="bg-black opacity-80 py-4 rounded-3xl px-7">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4 text-white ">
                  500,000+ <br/> Impressions
                </h1>
                <p className=" flex flex-row justify-end"><i>*per month</i></p>
              </div>
              <div className="bg-black opacity-80 py-4 rounded-3xl px-7">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4 text-white ">
                  69% <br/>Re-routes
                </h1>
                <p className=" flex flex-row justify-end"><i>*To your business</i></p>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default Dashboard;
