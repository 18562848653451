import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [underlineStyle, setUnderlineStyle] = useState({});
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const activeLink = document.querySelector(`.nav-link[href="${location.pathname}"]`);
    if (activeLink) {
      setUnderlineStyle({
        width: activeLink.offsetWidth,
        left: activeLink.offsetLeft,
      });
    }
  }, [location.pathname]);

  const handleLinkClick = (e) => {
    setUnderlineStyle({
      width: e.target.offsetWidth,
      left: e.target.offsetLeft,
    });
    setIsOpen(false); // Close the menu when a link is clicked
  };

  const isActive = (path) => {
    return location.pathname === path ? "text-black font-extrabold underline underline-offset-8" : "text-custom-pink";
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-white bg-opacity-20 backdrop-blur-xl z-50 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <img className="h-14 w-auto" src={Logo} alt="Logo" />
          </div>
          <div className="hidden md:flex space-x-8 pt-5 relative">
            <span className="underline-animation" style={underlineStyle}></span>
            <Link to="/" className={`nav-link ${isActive("/")} hover:text-gray-500`} onClick={handleLinkClick}>
              Home
            </Link>
            <Link to="/whatwedo" className={`nav-link ${isActive("/whatwedo")} hover:text-gray-500`} onClick={handleLinkClick}>
              What We Do
            </Link>
            <Link to="/services" className={`nav-link ${isActive("/services")} hover:text-gray-500`} onClick={handleLinkClick}>
              Services
            </Link>
            <Link to="/contact" className={`nav-link ${isActive("/contact")} hover:text-gray-500`} onClick={handleLinkClick}>
              Contact
            </Link>
            <Link to="/captain" className="bg-black hover:bg-custom-pink text-white font-bold h-7 px-4">
              كابتن
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-custom-pink hover:text-custom-pink focus:outline-none focus:text-custom-pink"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/" className={`${isActive("/")} block px-3 py-2 rounded-md text-base font-medium hover:text-gray-500 hover:bg-gray-100`} onClick={handleLinkClick}>
              Home
            </Link>
            <Link to="/whatwedo" className={`${isActive("/whatwedo")} block px-3 py-2 rounded-md text-base font-medium hover:text-gray-500 hover:bg-gray-100`} onClick={handleLinkClick}>
              What We Do
            </Link>
            <Link to="/services" className={`${isActive("/services")} block px-3 py-2 rounded-md text-base font-medium hover:text-gray-500 hover:bg-gray-100`} onClick={handleLinkClick}>
              Services
            </Link>
            <Link to="/contact" className={`${isActive("/contact")} block px-3 py-2 rounded-md text-base font-medium hover:text-gray-500 hover:bg-gray-100`} onClick={handleLinkClick}>
              Contact
            </Link>
            <Link to="/captain" className={`${isActive("/captain")} block px-3 py-2 rounded-md text-base font-medium hover:text-gray-500 hover:bg-gray-100`} onClick={handleLinkClick}>
              كابتن
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
