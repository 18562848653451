import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const DriverContact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    phone: '',
    interest: '',
    subject: '',
    message: '',
    consent: false,
    notARobot: false,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_66a7u4o',
      'template_857geft',
      formData,
      'Biuo-xx3R_rHhGr0C'
    )
    .then((result) => {
      console.log(result.text);
      navigate('/thank-you');
    }, (error) => {
      console.log(error.text);
      alert("An error occurred, please try again later.");
    });
  };

  return (
    <div className="bg-gray-100 py-12" dir="rtl">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900">اتصل بنا</h1>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">الاسم الأول *</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="الاسم الأول"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">الاسم الأخير *</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="الاسم الأخير"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">البريد الإلكتروني *</label>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="البريد الإلكتروني"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">رقم الهاتف *</label>
              <input
                id="phone"
                name="phone"
                type="number"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="+962 ___ - ___ - ____"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="interest" className="block text-sm font-medium text-gray-700">اختر خيارًا*</label>
              <select
                id="interest"
                name="interest"
                required
                className="mt-1 p-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={formData.interest}
                onChange={handleChange}
              >
                <option value="">Uber</option>
                <option value="advertising">Careem</option>
                <option value="partnership">Jeeny</option>
              </select>
            </div>


            <div className="md:col-span-2">
              <label className="flex items-start text-sm font-medium text-gray-700">
                <input
                  name="consent"
                  type="checkbox"
                  required
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={formData.consent}
                  onChange={handleChange}
                />
                <span className="ml-2">
                  أوافق على تلقي شركة EasyAds معلوماتي من أجل إبلاغي بالموضوع.
                </span>
              </label>
            </div>
            <div className="md:col-span-2">

            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-custom-pink hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              إرسال
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DriverContact;
