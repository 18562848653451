import React from "react";
import privacyImage from "../../assets/EasyAds.gif";

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div>
            <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
              Privacy Policy
            </h2>
            <p className="text-lg text-gray-700 mb-4">
              This Privacy Policy describes how EasyAds collects and uses
              Personal Data about you through the use of our Website, mobile
              applications, and through email, text, and other electronic
              communications between you and EasyAds.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              EasyAds (“EasyAds” or “we” or “our”) respects your privacy and is
              committed to protecting it through our compliance with this
              policy.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              This policy applies to the information we collect:
            </p>
            <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
              <li>
                From you on or through our Website, Application and Social
                Media;
              </li>
              <li>
                In email, text, and other electronic messages between you and
                our Website or Application;
              </li>
              <li>Through printed documents, records, and materials; and</li>
              <li>
                When you interact with our advertising, marketing, and
                applications on third-party websites and services, if those
                applications or advertising include links to this policy.
              </li>
            </ul>
            <p className="text-lg text-gray-700 mb-4">
              Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. If
              you do not agree with our policies and practices, you are not
              permitted to use our Website or Application. By accessing or using
              our Website or Application, you agree to the terms and conditions
              of this Privacy Policy. This Privacy Policy may change from time
              to time. Your continued use of our Website or Application after we
              make changes shall constitute your acceptance of those changes.
            </p>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Data We Collect About You and How We Collect It
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              Our app evaluates the GPS data in the background to present ads
              that resonate with the local audience's preferences and current
              events. We ensure this process fully respects user privacy; the
              app does not store personal data or track individual user location
              history. The background location access is solely for real-time ad
              adjustment and is critical for the app to function.
            </p>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              How We collect this information:
            </h3>
            <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
              <li>Directly from you when you provide it to us;</li>
              <li>
                Automatically as you navigate through our website or use the
                application. Information collected automatically may include
                usage details, IP addresses, and information collected through
                cookies, web beacons, and other tracking technologies; and
              </li>
              <li>From third parties, for example, our business partners.</li>
            </ul>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Data You Provide Us
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              The information and data we collect on or through our Website and
              Application are:
            </p>
            <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
              <li>
                Information and data that you provide by filling in forms on our
                Website and Application if it exists. This includes information
                and data provided at the time of registering to use our Website
                or Application, subscribing to our service, posting material, or
                requesting further services. We may also ask you for information
                when you enter a contest or promotion sponsored by us, or during
                a study, you consent to participate in, and when you report a
                problem with our Website or Application;
              </li>
              <li>
                Records and copies of your correspondence (including email
                addresses), if you contact us;
              </li>
              <li>
                Your responses to surveys that we might ask you to complete for
                research purposes; and
              </li>
              <li>
                Details of transactions you carry out through our Application
                and Website and of the fulfilment of your orders. You may be
                required to provide financial information before placing an
                order through our Website or our Application if it exists.
              </li>
            </ul>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              How We Use Your Data
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              We use your Personal Data for various purposes described below,
              including to:
            </p>
            <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
              <li>Provide our Application and Website to you;</li>
              <li>Enforce our rights arising from contracts;</li>
              <li>
                Notify you about changes; and provide you with notices about
                your account.
              </li>
              <li>
                To present our Website and Application and their contents to
                you;
              </li>
              <li>
                To fulfil any other lawful purpose for which you provide it;
              </li>
              <li>
                To provide you with notices about your membership, including
                expiration and renewal notices;
              </li>
              <li>To authenticate your identity;</li>
              <li>
                To monitor your compliance with any of your agreements with us;
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection;
              </li>
              <li>
                To notify you about changes to our Website or Application or any
                products or services we offer or provide through them;
              </li>
              <li>
                To allow you to participate in interactive features on our
                Website and Application;
              </li>
              <li>
                To allow you to participate in, and us to administer, our
                contests, promotions and surveys.
              </li>
              <li>
                To manage or transfer our assets or liabilities, for example in
                the case of an actual or potential acquisition, disposition or
                merger;
              </li>
              <li>
                In any other way we may describe when you provide the
                information; and for any other purpose with your consent, or as
                otherwise required or permitted by applicable law.
              </li>
            </ul>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Disclosure of Your Data
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              We do not share, sell, or otherwise disclose your Personal Data
              for purposes other than those outlined in this Privacy Policy. We
              disclose your Personal Data to a few third parties, including:
            </p>
            <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
              <li>Our subsidiaries and affiliates;</li>
              <li>
                Our third-party service providers, subcontractors, and business
                associates we use to support our business;
              </li>
              <li>
                To any company, we might merge with or acquire, or that buys us,
                or in the event of a change in the structure of our company of
                any form;
              </li>
              <li>To comply with our legal obligations;</li>
              <li>To enforce our rights; and with your consent.</li>
            </ul>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Data Subject Rights
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              At any time, data subjects can contact EasyAds in order to
              exercise the following rights:
            </p>
            <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
              <li>Right to access to their personal data;</li>
              <li>
                Right to modify or erase their personal data, subject to the
                legal requirements applicable in the data subject’s country;
              </li>
              <li>Right to restrict personal data processing;</li>
              <li>Right to oppose personal data processing;</li>
              <li>Right to personal data portability.</li>
            </ul>
            <p className="text-lg text-gray-700 mb-4">
              To exercise these rights, data subjects are invited to contact
              EasyAds by email, at info@EasyAds.com
            </p>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Changes to Agreement and Additional Rules of Usage
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              EasyAds also reserves the right to post, from time to time,
              additional rules of usage that apply to specific parts of the
              Site, which may be posted in the relevant parts of the Site, and
              will be clearly identified. Your continued use of the Site
              constitutes your agreement to comply with these additional rules.
            </p>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Site Information is Provided "As Is"
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              Site Information is provided "as is" with all faults. You use the
              Site Information, as authorized herein, at your own risk. Site
              Information may contain errors, omissions, or typographical errors
              or may be out of date. The Site may change, delete or update any
              Site Information at any time and without prior notice.
            </p>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Credit Card Data Storage
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              All credit/debit card details and personally identifiable
              information will NOT be stored, sold, shared, rented or leased to
              any third parties.
            </p>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Non-Commercial Use Only
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              The Site is for the personal use of individuals only and may not
              be used in connection with any commercial endeavours.
              Organizations, companies and/or businesses may not use the
              Services, the Site Information or the Site for any purpose without
              written consent of or agreement with EasyAds. You agree not to
              make use of Site Information by publication, re-transmission,
              distribution, performance, caching, or otherwise, except as
              permitted by law or as expressly permitted in writing by this
              Agreement or EasyAds. Illegal and/or unauthorized uses of the
              Site, including efforts to collect usernames and/or email
              addresses of users by electronic or other means for the purpose of
              sending unsolicited emails and unauthorized framing of or linking
              to the Site, will be investigated and appropriate legal action
              will be taken, including without limitation, civil, criminal and
              injunctive redress.
            </p>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Trademark Notice
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              EasyAdsMedia and the Site names, logos, and other identifying
              marks are the property of EasyAdsMedia. Other featured words or
              symbols, used to identify the source of merchandise or services,
              may be the trademarks of their respective owners.
            </p>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              Contact Information
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              You may contact us through the contact information below.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              If you have any questions, concerns, complaints or suggestions
              regarding our Privacy Policy, have any requests related to your
              Personal Data, or otherwise need to contact us, you may do so at:
            </p>
            <p className="text-lg text-gray-700 mb-4">info@EasyAdsMedia.com</p>
          </div>
          <div className=" order-last lg:order-none">
            <img
              src={privacyImage}
              alt="Privacy Policy"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
